import request from "@/utils/request";
import { TABLE_PAGE_SIZE } from "@/constants/global";

export const getListDriver = (params) =>
  request({
    method: "get",
    endpoint: "/driver/api/v1/carrier/drivers",
    params: {
      ...params,
      limit: TABLE_PAGE_SIZE,
    },
  });

export const addDriver = (data) =>
  request({
    method: "post",
    endpoint: `/driver/api/v1/carrier/drivers/add_driver`,
    data,
  });

export const deleteDriver = (driverId) =>
  request({
    method: "put",
    endpoint: `/driver/api/v1/carrier/drivers/${driverId}/remove_from_carrier`,
  });
