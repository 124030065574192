import { ORDER_STATUS, ORDER_TYPE } from "@/constants/order";

export default {
  "order.orders_page_title": "Quản lý đơn hàng",
  "order.order_detail_page_title": "Đơn hàng #{{orderId}}",
  "order.display": "Hiển thị",
  "order.pickup_location": "Điểm bốc",
  "order.dropoff_location": "Điểm dỡ",
  "order.pickup_date": "Ngày bốc hàng",
  "order.dropoff_date": "Ngày dỡ hàng",
  "order.trip_price": "Giá chuyến",
  "order.order_summary": "Thông tin đơn hàng",
  "order.shipment_list": "Danh sách shipment",
  "order.total_distance": "Tổng khoảng cách",
  "order.detailed_address": "Địa chỉ chi tiết",
  "order.person_to_contact": "Người liên hệ",
  "order.order_type": "Loại đơn",
  "order.share_truck": "Cần ghép hàng",
  "order.size_in_meter": "Kích thước (m)",
  "order.cargo_type": "Loại hàng",
  "order.truck_type": "Loại xe",
  "order.truck_model": "Trọng tải xe",
  "order.truck_quantity": "Số lượng xe",
  "order.index": "STT",
  "order.choose_driver": "Chọn tài xế",
  "order.choose_truck": "Chọn xe",
  [`order.status_${ORDER_STATUS.POSTED}`]: "Mới tạo",
  [`order.status_${ORDER_STATUS.HAS_BIDDING}`]: "Có báo giá",
  [`order.status_${ORDER_STATUS.BIDDING_ACCEPTED}`]: "Đã chốt giá",
  [`order.status_${ORDER_STATUS.PAID}`]: "Đã thanh toán",
  [`order.status_${ORDER_STATUS.SHIPMENT_NEW}`]: "Đã chốt tài xế",
  [`order.status_${ORDER_STATUS.SHIPMENT_IN_TRANSIT}`]: "Đang chạy",
  [`order.status_${ORDER_STATUS.CANCELLED}`]: "Đã hủy",
  [`order.status_${ORDER_STATUS.COMPLETED}`]: "Hoàn thành",
  [`order.type_${ORDER_TYPE.ENTERPRISE}`]: "Đơn Enterprise",
  [`order.type_${ORDER_TYPE.MARKETPLACE}`]: "Đơn Marketplace",
  [`order.type_${ORDER_TYPE.ORIGINAL_HYBRID}`]: "Đơn Logivan Direct",
  "order.no_assigned_drivers": "Chưa gán tài",
  "order.pickup_datetime": "Thời điểm bốc",
  "order.dropoff_datetime": "Thời điểm dỡ",
};
