import { TABLE_PAGE_SIZE } from "@/constants/global";

export const openInNewTab = (href) => {
  Object.assign(document.createElement("a"), {
    target: "_blank",
    href,
  }).click();
};

export const configPagination = (pagination) => ({
  pageSize: TABLE_PAGE_SIZE,
  total: pagination?.total || pagination?.total_count || 0,
  current: pagination?.current_page || 0,
});
