import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { getLanguage } from "@/utils/config";

import en from "./en";
import vi from "./vi";

i18n.use(initReactI18next).init({
  lng: getLanguage() || "vi",
  fallbackLng: "vi",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },

  resources: {
    en: {
      translation: en,
    },
    vi: {
      translation: vi,
    },
  },
});

export default i18n;
