import { DRIVER_STATUS } from "@/constants/driver";

export default {
  "drivers.drivers_page_title": "Driver management",
  "drivers.driver_id": "Driver Id",
  "drivers.date_joined": "Date joined",
  "drivers.total_trip": "Total trip",
  "drivers.total_success_trip": "Success",
  "drivers.total_cancelled_trip": "Cancelled",
  [`drivers.status_${DRIVER_STATUS.PENDING}`]: "Pending",
  [`drivers.status_${DRIVER_STATUS.ONLINE}`]: "Online",
  [`drivers.status_${DRIVER_STATUS.OFFLINE}`]: "Offline",
  [`drivers.status_${DRIVER_STATUS.APP_INSTALLED}`]: "Installed app",
  [`drivers.status_${DRIVER_STATUS.APP_NOT_INSTALLED}`]: "Not installed app",
  "drivers.add_new_driver": "Add new driver",
  "drivers.driver_name": "Driver's name",
  "drivers.send_sms": "Send SMS",
  "drivers.send_sms_text_description":
    "Your driver will receive a group message via the phone number registered below.",
  "drivers.send_invitation_successfully": "Send invitation successfully",
  "drivers.have_you_informed_logivan_yet": "Have you informed Logivan yet?",
  "drivers.confirm_delete_driver_description":
    "Before performing this action, you need to notify Logivan about removing this driver from the list that you have previously registered with Logivan. If you have already been told, please continue to take this action.",
  "drivers.confirm_delete": "Confirm delete",
};
