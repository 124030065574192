import React from "react";

import useUnauthorizedListener from "@/hooks/useUnauthorizedListener";
import useMessageListener from "@/hooks/useMessageListener";
import useErrorNotificationListener from "@/hooks/useErrorNotificationListener";
import useRehydrateStore from "@/hooks/useRehydrateStore";

import Routes from "./pages";

function App() {
  useUnauthorizedListener();
  useMessageListener();
  useErrorNotificationListener();
  const isRehydrating = useRehydrateStore();

  if (isRehydrating) return null;

  return <Routes />;
}

export default App;
