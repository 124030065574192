export const login = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getLoginUser = () => {
  try {
    return JSON.parse(localStorage.getItem("user"));
  } catch (err) {
    return null;
  }
};

export const getAuthToken = () => {
  const user = getLoginUser();
  if (!user) return "";
  return user.authentication_token;
};
