import { combineReducers } from "redux";

import userReducer, { removeUser } from "./slices/user";
import orderManagementReducer from "./slices/orderManagement";
import orderDetailReducer from "./slices/orderDetail";
import driverManagementReducer from "./slices/driverManagement";
import truckManagementReducer from "./slices/truckManagement";

const appReducer = combineReducers({
  user: userReducer,
  orderManagement: orderManagementReducer,
  orderDetail: orderDetailReducer,
  driverManagement: driverManagementReducer,
  truckManagement: truckManagementReducer,
});

export default (state, action) => {
  if (action.type === removeUser.type) state = undefined;
  return appReducer(state, action);
};
