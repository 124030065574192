import axios from "axios";
import {
  SHOW_MESSAGE_EVENT,
  UNAUTHORIZED_EVENT,
  SHOW_ERROR_NOTIFICATION_EVENT,
} from "@/constants/event";
import { getBackendUrl } from "./config";
import { getAuthToken } from "./auth";

const axiosInstance = axios.create({
  baseURL: getBackendUrl(),
});

const handleError = (statusCode) => {
  const ev = new CustomEvent(SHOW_ERROR_NOTIFICATION_EVENT, {
    detail: {
      statusCode,
    },
  });
  document.dispatchEvent(ev);
};

const handle401 = () => {
  const ev = new CustomEvent(UNAUTHORIZED_EVENT);
  document.dispatchEvent(ev);
};

const handle422 = (originalRequest, response) => {
  if (!originalRequest.shouldShowErrorMessage(response)) return;

  const ev = new CustomEvent(SHOW_MESSAGE_EVENT, {
    detail: {
      type: "error",
      data: response?.data,
    },
  });
  document.dispatchEvent(ev);
};

axiosInstance.interceptors.response.use(
  (response) => {
    return { ...response.data, httpCode: response.status };
  },
  (error) => {
    if (!error.isAxiosError) return Promise.reject(error);

    const statusCode = error?.response?.status;

    if (statusCode === 422) {
      handle422(error.config, error.response);
    } else if (statusCode === 401) {
      handle401();
    } else {
      handleError(statusCode);
    }

    return Promise.reject(error);
  }
);

const request = async ({
  method,
  endpoint,
  data,
  params,
  isPublicApi = false, // isPublicApi doesn't need to have authorization token
  shouldShowErrorMessage = () => true, // a function that return true or false
} = {}) => {
  const headers = {
    Authorization: isPublicApi ? undefined : `Bearer ${getAuthToken()}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return axiosInstance(endpoint, {
    method,
    headers,
    params: params ? { ...params, app_name: "carrier_web" } : undefined,
    data: data ? { ...data, app_name: "carrier_web" } : undefined,
    shouldShowErrorMessage,
  });
};

export default request;
