import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getLoginUser } from "@/utils/auth";
import { saveUser } from "@/store/slices/user";

const useRehydrateStore = () => {
  const dispatch = useDispatch();
  const [isRehydrating, setIsRehydrating] = useState(true);

  useEffect(() => {
    const user = getLoginUser();
    if (user) {
      dispatch(saveUser(user));
    }
    setIsRehydrating(false);
  }, [dispatch]);

  return isRehydrating;
};

export default useRehydrateStore;
