import { TRUCK_STATUS } from "@/constants/truck";

export default {
  "trucks.trucks_page_title": "Trucks management",
  "trucks.index": "Index",
  "trucks.truck_type": "Truck type",
  "trucks.plate_number": "Plate number",
  "trucks.truck_size": "Truck size",
  [`trucks.status_${TRUCK_STATUS.ACCEPTED}`]: "Accepted",
  [`trucks.status_${TRUCK_STATUS.REJECTED}`]: "Rejected",
  [`trucks.status_${TRUCK_STATUS.REVIEWING}`]: "Reviewing",
};
