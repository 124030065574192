import viLocale from "date-fns/locale/vi";
import enLocale from "date-fns/locale/en-US";

export const setLanguage = (language) => {
  localStorage.setItem("language", language);
};

export const getLanguage = () => localStorage.getItem("language");

export const getDateFnsLocale = () => {
  const currentLng = getLanguage();
  if (currentLng === "en") return enLocale;
  return viLocale;
};

export const getBackendUrl = () => {
  if (process.env.REACT_APP_APP_ENV === "production")
    return process.env.REACT_APP_API_URL;

  return localStorage.getItem("api_url") || process.env.REACT_APP_API_URL;
};
