import { createSlice } from "@reduxjs/toolkit";
import { configPagination } from "@/utils/utils";
import { fetchOrders } from "@/api/order";

const slice = createSlice({
  name: "orderManagement",
  initialState: {
    filter: {
      status: null,
    },
    list: [],
    pagination: {},
  },
  reducers: {
    saveOrderList(state, action) {
      state.filter = action.payload.filter;
      state.list = action.payload.list;
      state.pagination = configPagination(action.payload.pagination);
    },
  },
});

export const getOrders = (params) => async (dispatch) => {
  try {
    const resp = await fetchOrders(params);
    dispatch(
      slice.actions.saveOrderList({
        list: resp.orders,
        pagination: resp.pages,
        filter: params,
      })
    );
    return Promise.resolve(resp);
  } catch (err) {
    return Promise.reject(err);
  }
};

export default slice.reducer;
