export default {
  "profile.page_title": "Account info",
  "profile.personal_info": "Personal info",
  "profile.company_info": "Company info",
  "profile.tax_number": "Tax number",
  "profile.company_name": "Company name",
  "profile.id_card_label": "Identity document",
  "profile.change_note":
    "*If you have any changes in documents, please contact us to update as soon as possible",
  "profile.transportation_permit_document": "Transportation permit document",
  "profile.business_registration_document": "Business registration document",
};
