export default {
  "global.app_name": "Logivan Carrier",
  "global.login": "Login",
  "global.logout": "Logout",
  "global.next": "Next",
  "global.page_not_found": "Page not found",
  "global.required_field": "Required field",
  "global.phone_number": "Phone number",
  "global.email_address": "Email address",
  "global.email": "Email",
  "global.full_name": "Full name",
  "global.address": "Address",
  "global.phone_number_wrong_format": "Wrong phone number format",
  "global.order_id": "Order ID",
  "global.status": "Status",
  "global.driver": "Driver",
  "global.shipper": "Shipper",
  "global.confirm": "Confirm",
  "global.account_profile": "Account info",
  "global.delete": "Delete",
  "global.index": "Index",
  "global.resend": "Resend",
  "global.display": "Display",
  "global.close": "Close",
  "global.created_at": "Created At",
  "global.tons": "tons",
  "global.yes": "Yes",
  "global.no": "No",
  "global.all": "All",
  "global.error": "Error",
  "global.add_new": "Add new",
  "global.cancel": "Cancel",
};
