export default {
  "errors.code.ARE-0000": "Bạn không có quyền truy cập thông tin này",
  "errors.code.ARE-0002": "Mã xác nhận không chính xác",
  "errors.code.ARE-0003": "Mã xác nhận đã hết hạn",
  "errors.code.ARE-0004":
    "Vượt quá số lần gửi lại mã xác nhận cho phép. Vui lòng thử lại sau 30 phút nữa!",
  "errors.code.ARE-0008": "Số điện thoại sai định dạng",
  "errors.http_status_401": "Không có quyền",
  "errors.http_status_401_description": "Bạn không có quyền truy cập",
  "errors.http_status_406_description":
    "Các định dạng của yêu cầu không có sẵn.",
  "errors.http_status_410_description":
    "Tài nguyên được yêu cầu sẽ bị xóa vĩnh viễn và sẽ không được lấy.",
  "errors.http_status_500_description":
    "Lỗi máy chủ, vui lòng kiểm tra máy chủ.",
  "errors.http_status_502_description": "Yêu cầu không hợp lệ.",
  "errors.http_status_503_description": "Máy chủ quá tải hoặc đang bảo trì.",
  "errors.http_status_504_description": "Máy chủ không hoạt động.",
  "errors.http_status_undefined_description": "Không kết nối được đến máy chủ.",
  "errors.global_error": "Có lỗi xảy ra",
};
