import { createSlice } from "@reduxjs/toolkit";
import { login, logout } from "@/utils/auth";
import { pick } from "lodash-es";

import {
  login as loginApi,
  getProfile as getProfileApi,
  logout as logoutApi,
} from "@/api/user";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loginUser: null,
    profile: null,
  },
  reducers: {
    saveUser(state, action) {
      state.loginUser = action.payload;
    },
    removeUser(state, action) {
      state.loginUser = null;
    },
    saveProfile(state, action) {
      state.profile = action.payload;
    },
  },
});

export const { saveUser, removeUser } = userSlice.actions;

export const loginRequest = (phoneNumber, otpToken) => async (dispatch) => {
  try {
    const resp = await loginApi(phoneNumber, otpToken);
    const carrier = resp?.driver?.data?.user;
    const user = pick(carrier, [
      "id",
      "phone_number",
      "email",
      "authentication_token",
      "avatar_url",
      "name",
    ]);
    login(user);
    dispatch(userSlice.actions.saveUser(user));
    return Promise.resolve(resp);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const logoutRequest = () => async (dispatch) => {
  try {
    logoutApi();
    logout();
    dispatch(userSlice.actions.removeUser());
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getProfileRequest = () => async (dispatch) => {
  try {
    const resp = await getProfileApi();
    dispatch(userSlice.actions.saveProfile(resp.driver));
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export default userSlice.reducer;
