export default {
  "login.welcome_message": "Welcome! Carriers",
  "login.otp_code": "OTP Code",
  "login.input_otp_code_description":
    "Please input the OTP code we sent to {{phoneNumber}}",
  "login.resend_otp_description": "You can resend OTP code in {{duration}}",
  "login.resend_otp": "Resend OTP code",
  "login.term_line_1": "By clicking next, I accept",
  "login.term_line_2":
    "<privacy>Privacy Policy</privacy> & <term>Terms of Service</term>",
  "login.user_not_found": "User not found",
  "login.user_not_found_detail":
    "Your account is not found or it isn't verified, please contact us for more information.",
};
