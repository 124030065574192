import { createSlice } from "@reduxjs/toolkit";
import { fetchOrderDetail } from "@/api/order";

const orderDetailSlice = createSlice({
  name: "orderDetail",
  initialState: {
    order: {},
    shipments: [],
    offers: [],
    truckRequests: [],
  },
  reducers: {
    saveOrderDetail(state, action) {
      const {
        order: { shipments, offers, ...order },
      } = action.payload;
      state.order = order;
      state.offers = offers || [];
      state.shipments = shipments || [];
      state.truckRequests = offers.reduce((data, offer) => {
        const truckRequests = (offer?.truck_requests || []).map((item) => ({
          ...item,
          offer_id: offer.id,
        }));
        return [...data, ...truckRequests];
      }, []);
    },
    assignDriver(state, action) {
      state.order.status = action.payload.orderStatus;
      state.shipments = action.payload.shipments;
    },
  },
});

export const getOrderDetail = (id) => async (dispatch) => {
  try {
    const resp = await fetchOrderDetail(id);
    dispatch(orderDetailSlice.actions.saveOrderDetail(resp));
    return Promise.resolve(resp);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const { assignDriver } = orderDetailSlice.actions;
export default orderDetailSlice.reducer;
