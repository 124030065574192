import { TRUCK_STATUS } from "@/constants/truck";

export default {
  "trucks.trucks_page_title": "Quản lý xe",
  "trucks.index": "STT",
  "trucks.truck_type": "Loại xe",
  "trucks.plate_number": "Biển số xe",
  "trucks.truck_size": "Kích thước xe",
  [`trucks.status_${TRUCK_STATUS.ACCEPTED}`]: "Đã chấp nhận",
  [`trucks.status_${TRUCK_STATUS.REJECTED}`]: "Đã từ chối ",
  [`trucks.status_${TRUCK_STATUS.REVIEWING}`]: "Đang duyệt",
};
