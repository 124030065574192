import global from "./global";
import login from "./login";
import order from "./order";
import profile from "./profile";
import driver from "./driver";
import trucks from "./trucks";
import errors from "./errors";

export default {
  ...global,
  ...login,
  ...order,
  ...profile,
  ...trucks,
  ...driver,
  ...errors,
};
