import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notification } from "antd";

import { UNAUTHORIZED_EVENT } from "@/constants/event";
import { logoutRequest } from "@/store/slices/user";
import { useDispatch } from "react-redux";

let isLoggingOut = false;

const useUnauthorizedListener = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const unauthorizedHandler = () => {
      notification.error({
        key: "unauthorized",
        message: t("errors.http_status_401"),
        description: t("errors.http_status_401_description"),
      });

      if (!isLoggingOut) {
        isLoggingOut = true;
        dispatch(logoutRequest()).then(() => {
          isLoggingOut = false;
          history.push("/login");
        });
      }
    };
    document.addEventListener(UNAUTHORIZED_EVENT, unauthorizedHandler);
    return () =>
      document.removeEventListener(UNAUTHORIZED_EVENT, unauthorizedHandler);
  }, [history, t, dispatch]);
};

export default useUnauthorizedListener;
