export default {
  "errors.code.ARE-0000": "You don't have authorization to access",
  "errors.code.ARE-0002": "Wrong OTP number",
  "errors.code.ARE-0003": "OTP number is expired",
  "errors.code.ARE-0004":
    "You requested OTP for too many times. Please try again after 30 minutes!",
  "errors.code.ARE-0008": "Wrong phone number format",
  "errors.http_status_401": "Unauthorized",
  "errors.http_status_401_description": "You don't have authority to access",
  "errors.http_status_406_description": "Not Acceptable.",
  "errors.http_status_410_description": "Gone.",
  "errors.http_status_500_description": "Internal Server Error.",
  "errors.http_status_502_description": "Bad Gateway.",
  "errors.http_status_503_description": "Service Unavailable.",
  "errors.http_status_504_description": "Gateway Timeout.",
  "errors.http_status_undefined_description": "Can not connect to the server.",
  "errors.global_error": "An error occurred",
};
