export default {
  "profile.page_title": "Thông tin tài khoản",
  "profile.personal_info": "Thông tin cá nhân",
  "profile.company_info": "Thông tin doanh nghiệp",
  "profile.tax_number": "Mã số thuế",
  "profile.company_name": "Tên doanh nghiệp",
  "profile.id_card_label": "Chứng minh nhân dân/Thẻ căn cước",
  "profile.change_note":
    "*Nếu giấy tờ của bạn có bất kì thay đổi nào, vui lòng liên hệ để được cập nhật sớm nhất",
  "profile.transportation_permit_document": "Giấy phép vận tải",
  "profile.business_registration_document": "Giấy đăng kí kinh doanh",
};
