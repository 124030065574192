import { DRIVER_STATUS } from "@/constants/driver";

export default {
  "drivers.drivers_page_title": "Quản lý tài xế",
  "drivers.driver_id": "Mã tài xế",
  "drivers.date_joined": "Ngày gia nhập",
  "drivers.total_trip": "Số chuyến",
  "drivers.total_success_trip": "Hoàn thành",
  "drivers.total_cancelled_trip": "Thất bại",
  "drivers.delete_driver_confirm": "Bạn có chắc là muốn xóa tài xế này?",
  [`drivers.status_${DRIVER_STATUS.PENDING}`]: "Đang đợi",
  [`drivers.status_${DRIVER_STATUS.ONLINE}`]: "Online",
  [`drivers.status_${DRIVER_STATUS.OFFLINE}`]: "Offline",
  [`drivers.status_${DRIVER_STATUS.APP_INSTALLED}`]: "Đã cài app",
  [`drivers.status_${DRIVER_STATUS.APP_NOT_INSTALLED}`]: "Chưa cài app",
  "drivers.add_new_driver": "Thêm mới tài xế",
  "drivers.driver_name": "Tên tài xế",
  "drivers.send_sms": "Gửi SMS",
  "drivers.send_sms_text_description":
    "Tài xế của bạn sẽ nhận một tin nhắn xác nhận tham gia nhóm thông qua số điện thoại được đăng ký dưới đây.",
  "drivers.send_invitation_successfully": "Đã mời thành công",
  "drivers.have_you_informed_logivan_yet": "Bạn đã thông báo cho Logivan chưa?",
  "drivers.confirm_delete_driver_description":
    "Trước khi thực hiện hành động này, bạn cần phải thông báo cho Logivan biết về việc xoá tài xế này ra khỏi danh sách mà bạn đã đăng ký với Logivan trước đây. Nếu đã thông báo rồi, xin hãy tiếp tục thực hiện hành động này.",
  "drivers.confirm_delete": "Xác nhận xóa",
};
