import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { notification } from "antd";

import { SHOW_ERROR_NOTIFICATION_EVENT } from "@/constants/event";

const useErrorNotificationListener = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const handler = (ev) => {
      const statusCode = ev.detail?.statusCode;
      if ([401, 406, 410, 500, 502, 503, 504, undefined].includes(statusCode)) {
        notification.error({
          message: statusCode || t("global.error"),
          description: t(`errors.http_status_${statusCode}_description`),
        });
      } else {
        notification.error({
          message: t(`errors.global_error`),
        });
      }
    };
    document.addEventListener(SHOW_ERROR_NOTIFICATION_EVENT, handler);
    return () => {
      document.removeEventListener(SHOW_ERROR_NOTIFICATION_EVENT, handler);
    };
  }, [t]);
};

export default useErrorNotificationListener;
