export default {
  "login.welcome_message": "Xin chào! Chủ nhà xe",
  "login.otp_code": "Mã xác nhận",
  "login.input_otp_code_description":
    "Nhập mã xác nhận đã gửi tới số {{phoneNumber}}",
  "login.resend_otp_description": "Yêu cầu mã xác nhận mới {{duration}}",
  "login.resend_otp": "Gửi lại mã xác nhận",
  "login.term_line_1": "Bằng việc tiếp tục, tôi đồng ý",
  "login.term_line_2":
    "<privacy>Chính sách bảo mật</privacy> & <term>Điều khoản sử dụng</term>",
  "login.user_not_found": "Người dùng chưa có trên hệ thống",
  "login.user_not_found_detail":
    "Tài khoản của bạn chưa có trên hệ thống hoặc vẫn chưa được phê duyệt. Vui lòng liên hệ chúng tôi biết thêm thông tin chi tiết.",
};
