import { useEffect } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { SHOW_MESSAGE_EVENT } from "@/constants/event";

export const handleErrors = ({ data, t }) => {
  const { _error } = data;
  if (typeof _error === "string") {
    message.error(_error);
    return;
  }
  const msgs = _.get(data, "_error");
  let msg = "";
  if (Array.isArray(msgs) && msgs.length) {
    msg = msgs.join(" ");
    message.error(msg);
    return;
  }
  message.error(t("errors.global_error"));
};

export const errors = {};

const useMessageListener = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const errorMessageHandler = (ev) => {
      const type = ev.detail?.type || "success";
      const data = ev.detail?.data;
      const errorCode = data?.errorCode;

      if (!errorCode) {
        handleErrors({ data, t });
      } else message[type](t(`errors.code.${errorCode}`));
    };
    document.addEventListener(SHOW_MESSAGE_EVENT, errorMessageHandler);
    return () => {
      document.removeEventListener(SHOW_MESSAGE_EVENT, errorMessageHandler);
    };
  }, [t]);
};

export default useMessageListener;
