import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import PrivateRoute from "@/components/PrivateRoute";

import NotFound from "./NotFound";

const MenuLayout = React.lazy(() => import("@/layouts/MenuLayout"));
const OrdersPage = React.lazy(() => import("./Orders"));
const OrderDetailPage = React.lazy(() => import("./OrderDetail"));
const DriversPage = React.lazy(() => import("./Drivers"));
const TrucksPage = React.lazy(() => import("./Trucks"));
const LoginPage = React.lazy(() => import("./Login"));
const ProfilePage = React.lazy(() => import("./Profile"));

const Pages = () => (
  <Switch>
    <Route path="/login" component={LoginPage} />
    <PrivateRoute path="/">
      <MenuLayout>
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/orders" />} />
          <Route path="/orders/:id" component={OrderDetailPage} />
          <Route path="/orders" component={OrdersPage} />
          <Route path="/drivers" component={DriversPage} />
          <Route path="/trucks" component={TrucksPage} />
          <Route path="/profile" component={ProfilePage} />
          <NotFound />
        </Switch>
      </MenuLayout>
    </PrivateRoute>
  </Switch>
);

export default Pages;
