const loadDevTool = (callback) => {
  if (process.env.REACT_APP_APP_ENV === "production") {
    callback();
  } else {
    import("./index")
      .then((devTools) => devTools.install())
      .finally(callback);
  }
};

export default loadDevTool;
