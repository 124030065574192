import { createSlice } from "@reduxjs/toolkit";

import { getListDriver, deleteDriver, addDriver } from "@/api/driver";
import { configPagination } from "@/utils/utils";

const slice = createSlice({
  name: "driverManagement",
  initialState: {
    listDriver: [],
    pagination: null,
  },
  reducers: {
    saveListDriver(state, action) {
      const { listDriver, pagination } = action.payload;
      state.listDriver = listDriver;
      state.pagination = configPagination(pagination);
    },
    deleteDriver(state, action) {
      state.listDriver = state.listDriver.filter(
        (driver) => driver.id !== action.payload.driverId
      );
    },
    addDriver(state, action) {
      state.listDriver = [action.payload.driver, ...state.listDriver];
    },
  },
});

export const getListDriverRequest = (params) => async (dispatch) => {
  try {
    const resp = await getListDriver(params);
    dispatch(
      slice.actions.saveListDriver({
        listDriver: resp.drivers,
        pagination: resp.pages,
      })
    );
    return Promise.resolve(resp);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteDriverRequest = (driverId) => async (dispatch) => {
  try {
    const resp = await deleteDriver(driverId);
    dispatch(
      slice.actions.deleteDriver({
        driverId,
      })
    );
    return Promise.resolve(resp);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addDriverRequest = (driver) => async (dispatch) => {
  try {
    const resp = await addDriver(driver);
    dispatch(slice.actions.addDriver({ driver: resp.driver }));
    return Promise.resolve(driver);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const resendInviteRequest = (driver) => async () => {
  try {
    const resp = await addDriver(driver);
    return Promise.resolve(resp);
  } catch (err) {
    return Promise.reject(err);
  }
};

export default slice.reducer;
