import React from "react";

import styles from "./NotFound.module.less";
import { useTranslation } from "react-i18next";

const NotFound = (props) => {
  const { t } = useTranslation();
  return <div className={styles.container}>{t("global.page_not_found")}</div>;
};

export default NotFound;
