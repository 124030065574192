import request from "@/utils/request";
import { getLoginUser } from "@/utils/auth";

export const sendOtp = (phoneNumber) =>
  request({
    isPublicApi: true,
    method: "post",
    endpoint: "/account_regis/accounts/send_otp",
    data: {
      phone_number: phoneNumber,
      app_id: "legacy",
    },
    shouldShowErrorMessage: (response) => {
      const errorCode = response?.data?._error?.code;
      return !["ARE-0009", "ARE-0000"].includes(errorCode);
    },
  });

export const verifyOtp = (phoneNumber, otp) =>
  request({
    isPublicApi: true,
    method: "post",
    endpoint: "/account_regis/accounts/verify_otp",
    data: {
      phone_number: phoneNumber,
      otp_code: otp,
      app_id: "legacy",
    },
  });

export const login = (phoneNumber, otpToken) =>
  request({
    isPublicApi: true,
    method: "post",
    endpoint: "/driver/api/v1/drivers/register",
    data: {
      driver: {
        app_id: "legacy",
        phone_number: phoneNumber,
        otp_token: otpToken,
      },
    },
  });

export const logout = () =>
  request({
    method: "delete",
    endpoint: "/drivers/sign_out",
  });

export const getProfile = () => {
  const user = getLoginUser();
  if (!user) return Promise.reject(new Error("User not found"));

  return request({
    method: "get",
    endpoint: `/api/v2/drivers/${user.id}`,
  });
};
