export default {
  "global.app_name": "Logivan Chủ Xe",
  "global.login": "Đăng nhập",
  "global.logout": "Đăng xuất",
  "global.next": "Tiếp tục",
  "global.page_not_found": "Trang không tồn tại",
  "global.required_field": "Trường bắt buộc",
  "global.phone_number": "Số điện thoại",
  "global.email_address": "Địa chỉ email",
  "global.email": "Email",
  "global.full_name": "Họ và tên",
  "global.address": "Địa chỉ",
  "global.phone_number_wrong_format": "Số điện thoại không chính xác",
  "global.order_id": "Mã đơn hàng",
  "global.status": "Trạng thái",
  "global.driver": "Tài xế",
  "global.shipper": "Chủ hàng",
  "global.confirm": "Xác nhận",
  "global.account_profile": "Thông tin tài khoản",
  "global.delete": "Xóa",
  "global.index": "STT",
  "global.resend": "Gửi lại",
  "global.display": "Hiển thị",
  "global.close": "Đóng",
  "global.created_at": "Ngày tạo",
  "global.tons": "tấn",
  "global.yes": "Có",
  "global.no": "Không",
  "global.all": "Tất cả",
  "global.error": "Lỗi",
  "global.add_new": "Thêm mới",
  "global.cancel": "Hủy",
};
