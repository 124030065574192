import request from "@/utils/request";
import { TABLE_PAGE_SIZE } from "@/constants/global";

export const fetchOrders = (params) =>
  request({
    method: "get",
    endpoint: "/driver/api/v1/carrier/orders",
    params: {
      ...params,
      limit: TABLE_PAGE_SIZE,
    },
  });

export const fetchOrderDetail = (id) =>
  request({
    method: "get",
    endpoint: `/driver/api/v1/orders/${id}`,
  });

export const acceptOffers = (id, data) =>
  request({
    method: "put",
    endpoint: `/driver/api/v1/offers/${id}/accept`,
    data,
  });

export const fetchTrucks = (params) =>
  request({
    method: "get",
    endpoint: "/driver/api/v1/carrier/drivers/trucks",
    params,
  });

export const fetchMembers = (params) =>
  request({
    method: "get",
    endpoint: "/driver/api/v1/carrier/drivers/members",
    params,
  });
