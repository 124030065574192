import { ORDER_STATUS, ORDER_TYPE } from "@/constants/order";

export default {
  "order.orders_page_title": "Oders need truck",
  "order.order_detail_page_title": "Order #{{orderId}}",
  "order.display": "Display",
  "order.pickup_location": "Pickup location",
  "order.dropoff_location": "Dropoff location",
  "order.pickup_date": "Pickup date",
  "order.dropoff_date": "Dropoff date",
  "order.trip_price": "Price",
  "order.order_summary": "Order summary",
  "order.shipment_list": "Shipment list",
  "order.total_distance": "Total distance",
  "order.detailed_address": "Detailed address",
  "order.person_to_contact": "Person to contact",
  "order.order_type": "Order type",
  "order.share_truck": "Is share truck",
  "order.size_in_meter": "Size (m)",
  "order.cargo_type": "Cargo type",
  "order.truck_type": "Truck type",
  "order.truck_model": "Truck model",
  "order.truck_quantity": "Truck quantity",
  "order.index": "Index",
  "order.choose_driver": "Choose driver",
  "order.choose_truck": "Choose truck",
  [`order.status_${ORDER_STATUS.POSTED}`]: "Posted",
  [`order.status_${ORDER_STATUS.HAS_BIDDING}`]: "Has bidding",
  [`order.status_${ORDER_STATUS.BIDDING_ACCEPTED}`]: "Bidding accepted",
  [`order.status_${ORDER_STATUS.PAID}`]: "Paid",
  [`order.status_${ORDER_STATUS.SHIPMENT_NEW}`]: "Found truck",
  [`order.status_${ORDER_STATUS.SHIPMENT_IN_TRANSIT}`]: "In transit",
  [`order.status_${ORDER_STATUS.CANCELLED}`]: "Cancelled",
  [`order.status_${ORDER_STATUS.COMPLETED}`]: "Completed",
  [`order.type_${ORDER_TYPE.ENTERPRISE}`]: "Order Enterprise",
  [`order.type_${ORDER_TYPE.MARKETPLACE}`]: "Order Marketplace",
  [`order.type_${ORDER_TYPE.ORIGINAL_HYBRID}`]: "Order Logivan Direct",
  "order.no_assigned_drivers": "No assigned drivers",
  "order.pickup_datetime": "Pickup date time",
  "order.dropoff_datetime": "Dropoff date time",
};
