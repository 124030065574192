export const ORDER_STATUS = {
  POSTED: "posted",
  HAS_BIDDING: "has_bidding",
  BIDDING_ACCEPTED: "bidding_accepted",
  PAID: "paid",
  SHIPMENT_NEW: "shipment_new",
  SHIPMENT_IN_TRANSIT: "shipment_in_transit",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
};

export const ORDER_TYPE = {
  ORIGINAL_HYBRID: "original_hybrid",
  ENTERPRISE: "enterprise",
  MARKETPLACE: "marketplace",
};

export const BIDDER_TYPE = {
  LOGIVAN: "logivan",
  DRIVER: "driver",
};

export const BIDDING_STATUS = {
  ACCEPTED: "accepted",
};
