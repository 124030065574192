import { createSlice } from "@reduxjs/toolkit";
import { configPagination } from "@/utils/utils";
import { fetchTrucks } from "@/api/truck";

const slice = createSlice({
  name: "truckManagement",
  initialState: {
    list: [],
    pagination: {},
  },
  reducers: {
    saveTruckList(state, action) {
      state.list = action.payload.trucks;
      state.pagination = configPagination(action.payload.pages);
    },
  },
});

export const getTrucks = (params) => async (dispatch) => {
  try {
    const resp = await fetchTrucks(params);
    dispatch(slice.actions.saveTruckList(resp));
    return Promise.resolve(resp);
  } catch (err) {
    return Promise.reject(err);
  }
};

export default slice.reducer;
