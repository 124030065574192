import request from "@/utils/request";
import { TABLE_PAGE_SIZE } from "@/constants/global";

export const fetchTrucks = (params) =>
  request({
    method: "get",
    endpoint: "/driver/api/v1/carrier/trucks",
    params: {
      ...params,
      limit: TABLE_PAGE_SIZE,
    },
  });
